<template>
  <div class="project-list">
    <div class="project-list-header">
      <CategorySearch :source="filterSource" @change="search" />
      <a-button type="primary" @click="showModal(null)">添加</a-button>
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotID="{ row }">
        <router-link
          :to="{
            path: `/IoTManage/project/detail/${row.id}`,
            state: { tabName: row.name },
          }"
        >
          {{ row.bizProjectId }}
        </router-link>
      </template>
      <template #slotEnergyType="{ row }">
        {{ row.energyTypeName.join("，") }}
      </template>
      <template #slotAdress="{ row }">
        {{ row.addressName }}{{ row.address }}
      </template>
    </BasicTable>
  </div>
  <ProjectEditModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :detail="currentRow"
    @success="editSuccess"
  ></ProjectEditModal>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { apiProject } from "@/api/IoT/project.js";
import { apiDictListByCode } from "@/api/common.js";
import { Modal, message } from "ant-design-vue";
import ProjectEditModal from "./components/ProjectEditModal";
import _ from "lodash";

onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initTableData();
});

const filterSource = ref([
  {
    label: "项目名称",
    type: "input",
    bindKey: "name",
    placeholder: "请输入",
  },
  {
    label: "项目编码",
    type: "input",
    bindKey: "code",
    placeholder: "请输入",
  },
  {
    label: "项目业态",
    type: "select",
    bindKey: "bizType",
    options: [],
  },
  {
    label: "项目阶段",
    type: "select",
    bindKey: "status",
    options: [],
  },
]);
const initSearch = async () => {
  apiDictListByCode("PROJECT_BIZ_TYPE").then((res) => {
    const _list = _.cloneDeep(filterSource.value);
    _list[2].options = res.result;
    filterSource.value = _list;
  });
  apiDictListByCode("PROJECT_STATUS").then((res) => {
    const _list = _.cloneDeep(filterSource.value);
    _list[3].options = res.result;
    filterSource.value = _list;
  });
};
initSearch();
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 280px)",
  },
  columns: [
    {
      title: "ID",
      type: "customize",
      slotName: "slotID",
      width: 100,
    },
    {
      title: "项目名称",
      type: "text",
      key: "name",
      width: 180,
      ellipsis: true,
    },
    {
      title: "项目编码",
      type: "text",
      key: "code",
      width: 120,
    },
    {
      title: "业态",
      type: "text",
      key: "bizTypeName",
      width: 100,
    },
    {
      title: "面积（㎡）",
      type: "text",
      key: "area",
      width: 100,
    },
    {
      title: "能耗类型",
      type: "customize",
      slotName: "slotEnergyType",
      width: 100,
    },
    {
      title: "项目阶段",
      type: "text",
      key: "statusName",
      width: 80,
    },
    {
      title: "负责人",
      type: "text",
      key: "director",
      width: 80,
    },
    {
      title: "负责人电话",
      type: "text",
      key: "mobile",
      width: 100,
    },
    {
      title: "项目地址",
      type: "customize",
      slotName: "slotAdress",
      width: 300,
      ellipsis: true,
    },
  ],
  action: {
    title: "操作",
    width: 70,
    fixed: "right",
    items: [
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        onClick: (row) => {
          showModal(row);
        },
      },
      {
        type: "icon",
        icon: "DeleteOutlined",
        iconTip: "删除",
        onClick: (row) => {
          Modal.confirm({
            title: "提示",
            content: "是否删除该条数据？",
            okType: "danger",
            onOk: () => {
              return new Promise((resolve, reject) => {
                apiProject
                  .delete(row.id)
                  .then(() => {
                    message.success("删除成功");
                    initTableData();
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        },
      },
    ],
  },
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    const res = await apiProject.page(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const modalVisible = ref(false);
const currentRow = ref(null);
const showModal = (row) => {
  currentRow.value = row;
  modalVisible.value = true;
};
const editSuccess = () => {
  initTableData();
};
</script>
<style lang="less" scoped>
.project-list {
  height: 100%;
  padding: 16px 20px;
  background: #fff;
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
</style>
